import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import Table from 'react-bootstrap/Table'
import {topListByMarketCap} from '../api/ApiUtils';
import CustomPagination from './pagination';

function CryptoTable (props) {
  const [cryptoAssets, setCryptoAssets] = useState([]);
  const [pageNo, setPageNo] = useState(0);
  const [recordsCount, setRecordsCount] = useState(0)
  const [limit, setLimit] = useState(20)

  useEffect(() => {
      async function fetchMyAPI() {
        let response = await topListByMarketCap(pageNo, limit)
        setCryptoAssets(response.data.Data)
        setRecordsCount(response.data.MetaData.Count)
      }
      fetchMyAPI()
  },[])
  
  async function pageHandler (pageNo){
    setPageNo(pageNo)
    const response = await topListByMarketCap(pageNo, limit);
    setCryptoAssets(response.data.Data)
    setRecordsCount(response.data.MetaData.Count)
   
  }

  const history = useHistory();
  async function handleRowClick (cryptoAsset) {
    history.push(`/assets/${cryptoAsset.CoinInfo.Name}`)
  }
  
    return (
      <div>
        <Table hover responsive>
        <thead>
          <tr>
            <th>#</th>
            <th>Name</th>
            <th>Price</th>
            <th>24H Change</th>
            <th>Market Cap</th>
            <th>Circulating Supply</th>
          </tr>
        </thead>
        <tbody>
          {cryptoAssets.map((cryptoAsset, i) => {
            // console.log(cryptoAsset);
            let ChangeClass;
            let SortClass;
            cryptoAsset.RAW?.USD.CHANGEPCT24HOUR > 0 ? ChangeClass = "text-success" : ChangeClass = "text-danger";
            cryptoAsset.RAW?.USD.CHANGEPCT24HOUR > 0 ? SortClass = "fas fa-sort-up" : SortClass = "fas fa-sort-down";

            return (
            <tr key={i} onClick={() => handleRowClick(cryptoAsset)}>
              <td>{limit*pageNo+i+1}</td>
              <td>
                <img
                  style={{ height: "35px", width: "35px" }} 
                  src={`https://www.cryptocompare.com${cryptoAsset.CoinInfo.ImageUrl}`} alt="logo"/>
                {`${cryptoAsset.CoinInfo.FullName} ${cryptoAsset.CoinInfo.Name}`}</td>
              <td>{cryptoAsset.DISPLAY?.USD.PRICE}</td>
              <td className={ChangeClass}>
                {cryptoAsset.DISPLAY?.USD.CHANGEPCT24HOUR } 
                <i className={SortClass}></i>
                </td>
              <td>{cryptoAsset.DISPLAY?.USD.MKTCAP}</td>
              <td>{`${cryptoAsset.DISPLAY?.USD.SUPPLY.split(" ").slice(1)} ${cryptoAsset.CoinInfo.Name}`}</td>
            </tr>
            )
          })}
  
        </tbody>
      </Table>
        <div className="row">
          <div className="col">

            <span>{`Showing ${pageNo * limit + 1}-${(pageNo + 1) * limit} of ${recordsCount}`} </span>
          </div>
          <div className="col">

            <div className="text-center">
              <CustomPagination
                pageNo={pageNo}
                pages={Math.ceil(recordsCount / limit)}
                pageHandler={pageHandler} />
            </div>
          </div>
        </div>
      </div>

    );
  }


export default CryptoTable