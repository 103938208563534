import React, { useState, useEffect } from 'react';
import {fetchSingleNewsbySlug} from '../../api/ApiUtils';
import {TwitterShareButton, TwitterIcon, RedditShareButton, RedditIcon} from 'react-share';
import HorizontalBanner from "../adsBanners/horizontal";
import Sidebar from '../Sidebar';



function ComingSoon (props) {
    const [newsItem, setNewsItem] = useState([]);
    const [previousItem, setPreviousItem] = useState([]);
    const [nextItem, setNextItem] = useState([]);
    // console.log(props.properties.match.params.urlslug);
    useEffect(() => {
        async function fetchMyAPI() {
          let response = await fetchSingleNewsbySlug(props.properties.match.params.urlslug)
          console.log(response.data.data.news_feed);
          setNewsItem(response.data.data.news_feed)
          setPreviousItem(response.data.data.previous)
          setNextItem(response.data.data.next)
          
        }
        fetchMyAPI()
    },[])

    return (
        <div className="row">
        <div className="col-12 col-lg-8 card mt-5">
            <h1 className="card-header h2">{newsItem?.title}</h1>
            <div className="card-body">
                <h5 className="card-title">{newsItem?.news_room_name}</h5>
                <p>Views: <i className="fas fa-eye me-5">{" "+newsItem.views_count}</i></p>
                <img src={newsItem?.news_room_logo_url} alt="logoimg"/>
                <p className="card-text">{newsItem?.body}</p>
                <a href={newsItem?.news_item_url} target="_blank" className="btn btn-primary">Read more...</a>
               
                <div className="row mt-5">
                          <div className="col">
                            <TwitterShareButton
                              title={`${newsItem.title} #CryptoCurrencyNews`}
                              via={`coinjupiter`}
                              url={`https://www.coinjupiter.com/newsitem/${newsItem.url_slug}`}
                              children={
                                <TwitterIcon size={60} round={true} />
                              }
                            />
                            <RedditShareButton
                              title={newsItem.title}
                              url={`https://www.coinjupiter.com/newsitem/${newsItem.url_slug}`}
                              children={
                                <RedditIcon size={60} round={true} />
                              }
                            />
                          </div> 
                </div>
                <div className='row mt-5'>
              <div className='col'>
                <a href={`/newsitem/${previousItem?.url_slug}`} target="_blank">
                  <i className="fas fa-arrow-left me-1"></i>Previous
                </a>
              </div>
                  <div className='col'>
                  <a href={`/newsitem/${nextItem?.url_slug}`} target="_blank">
                              Next <i className="fas fa-arrow-right me-1"></i>
                </a>
                  </div>
                </div>
            </div>
          <HorizontalBanner />                      
        </div>
        <div className="col-sm-block col-lg-4 mt-5" style={{background: "white" }}>
          <Sidebar/>
        </div>
        </div>
        
    );
  }


export default ComingSoon