import React, { useState, useEffect } from 'react';
import { Form, Button, Card } from 'react-bootstrap';
import Moment from 'react-moment';
import 'moment-timezone';
import { latestCryptoNews, SearchEndpoint } from '../api/ApiUtils';
import CustomPagination from './pagination';
import Search from './search';
import { TwitterShareButton, TwitterIcon, RedditShareButton, RedditIcon } from 'react-share';
import ScrollButton from './htmlElements/scrollButton';
import HorizontalBanner from "../components/adsBanners/horizontal"
import Sidebar from './Sidebar';


function News(props) {
  const [cryptoNews, setCryptoNews] = useState([]);
  const [pageNo, setPageNo] = useState(0);
  const [recordsCount, setRecordsCount] = useState(0)
  const [limit, setLimit] = useState(10)
  const [Pages, setPages] = useState(0)

  useEffect(() => {
    async function fetchMyAPI() {
      let response = await latestCryptoNews(pageNo, limit)
      // console.log(response.data.data.news_feeds.news_feeds);
      setCryptoNews(response.data.data.news_feeds.news_feeds)
      setRecordsCount(response.data.data.news_feeds.total_news_feeds)
      setPages(response.data.data.news_feeds.pages)
    }
    fetchMyAPI()
  }, [])

  async function pageHandler(pageNo) {
    setPageNo(pageNo)
    const response = await latestCryptoNews(pageNo, limit);
    setCryptoNews(response.data.data.news_feeds.news_feeds)
    setRecordsCount(response.data.data.news_feeds.total_news_feeds)
    setPages(response.data.data.news_feeds.pages)
  }

  async function searchHandler(pageNo, limit, model, term) {
    setPageNo(pageNo)
    const response = await SearchEndpoint(model, term, limit, pageNo);
    setCryptoNews(response.data.data.news_feeds)
    setRecordsCount(response.data.data.total_news_feeds)
    setPages(response.data.data.pages)
  }
  return (
    <div className="mt-5">
      <div className="row">
        <div className="col">

          <span>{`Showing ${pageNo * limit + 1}-${(pageNo + 1) * limit} of ${recordsCount}`} </span>
        </div>
        <div className="col">

          <div className="text-center">
            <CustomPagination
              pageNo={pageNo}
              pages={Math.ceil(recordsCount / limit)}
              pageHandler={pageHandler} />
          </div>
        </div>

        <div className="col">

          <div className="text-center">
            <Search
              pageNo={pageNo}
              limit={limit}
              model="news"
              searchHandler={searchHandler} />
          </div>
        </div>
      </div>
      <div className = "row">
        <div className="col-12 col-lg-8" style={{background: "cream" }}>
        {cryptoNews?.map((newsItem, i) => {
        // console.log(newsItem)
        let categories;
        if (newsItem.tag) {
          categories = newsItem.tag?.split("|")
        }
        return (

          <Card style={{ width: '100%', borderRadius: "30px" }} className="mb-3 justify-content-center">
            <Card.Body>
              <div className="row">
                <div className="col-2 d-none d-lg-block">
                  <img
                    style={{ height: "50px", width: "50px" }}
                    src={newsItem.news_room_logo_url?newsItem.news_room_logo_url : null} />
                </div>
                <div className="col">
                  <Card.Title>{newsItem.news_room_name}</Card.Title>
                  <Card.Subtitle className="mb-2 text-muted">{newsItem.title}</Card.Subtitle>
                  <Card.Text>
                    {newsItem.body}
                  </Card.Text>
                  Categories:
                  {categories?.map((item, i) => {
                    return <span> {item} | </span>
                  })} <strong><Moment fromNow>{newsItem.createdAt}</Moment></strong>
                  <Card.Link href={`/newsitem/${newsItem.url_slug}`}><br />View!!</Card.Link>

                </div>
                <div className="row mt-1">
                  <div className="col">
                    <TwitterShareButton
                      title={`${newsItem.title} #CryptoCurrencyNews`}
                      via={`coinjupiter`}
                      url={`https://www.coinjupiter.com/newsitem/${newsItem.url_slug}`}
                      children={
                        <TwitterIcon size={40} round={true} />
                      }
                    />
                    <RedditShareButton
                      title={newsItem.title}
                      url={`https://www.coinjupiter.com/newsitem/${newsItem.url_slug}`}
                      children={
                        <RedditIcon size={40} round={true} />
                      }
                    />
                  </div>
                  <p>Views: <i className="fas fa-eye me-5">{" "+newsItem.views_count}</i></p>
                </div>
              </div>
            </Card.Body>
            {(i !== 0 && (i%5) === 0) ? <HorizontalBanner/> : ""}
            
          </Card>

        )
      })}
        </div>
        <div className="col-sm-block col-lg-4" style={{background: "white" }}>
          <Sidebar/>
        </div>

      </div>
    
      <div className="row">
        <div className="col">

          <span>{`Showing ${pageNo * limit + 1}-${(pageNo + 1) * limit} of ${recordsCount}`} </span>
        </div>
        <div className="col">

          <div className="text-center">
            <CustomPagination
              pageNo={pageNo}
              pages={Math.ceil(recordsCount / limit)}
              pageHandler={pageHandler} />
          </div>
        </div>
      </div>
      <ScrollButton />
    </div>

  );
}


export default News