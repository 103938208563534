import Container from 'react-bootstrap/Container'

import Header from '../components/header'
import Footer from '../components/footer'
import CryptoTable from '../components/cryptoTable'


function App() {
  return (
    <div className="App">
      <Header />

       <Container className="pt-5">
          <CryptoTable />
       </Container>
        
     <Footer />
    </div>
  );
}

export default App;



